import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import debug from 'debug'
import { Grid, Card, Header } from 'semantic-ui-react'
import { RestoreScrollPosition } from 'AppSrc/ui'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import Search from 'AppSrc/search/component'
import { selectors as searchSelectors } from 'AppSrc/search/reducer'
import PageLoader from 'AppSrc/components/pageloader'
import type { UpdateBottomMenuType } from 'AppSrc/components/bottommenu'
import RegistrantCard from './RegistrantCard'
import { isCheckedIn } from 'AppSrc/checkin/helpers'
import {
  getRegistrantsSubheader,
  getRegistrantsSubheaderNoneFound,
  getFilteredRegistrants,
  getItemInfo,
  getRegistrantCountsGroups,
} from './helpers'
import { registrantSort } from 'AppSrc/refresh/helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'
import { updateTopMenuContent } from 'AppSrc/components/topmenu/reducer'
import { historySaveItemPathname } from 'AppSrc/history/reducer'

debug.enable('registrantslist/index:*')
// const log = debug('registrantslist/index:log')
// const info = debug('registrantslist/index:info')
// const error = debug('registrantslist/index:error')

type Props = {
  updateBottomMenu: UpdateBottomMenuType
}

const RegistrantsList = ({ updateBottomMenu }: Props) => {
  const dispatch = useAppDispatch()

  const users = useAppSelector(state => refreshSelectors.users(state))
  const items = useAppSelector(state => refreshSelectors.items(state))
  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const registrants = useAppSelector(state => refreshSelectors.registrants(state))
  const activeSearchString = useAppSelector(state => searchSelectors.activeSearchString(state))

  const match = { params: useParams() }

  const { itemId, itemTitle, itemIdArray } = getItemInfo({
    items,
    allItems,
    settings,
    match,
  })

  const { registrantCounts, registrantGroups } = getRegistrantCountsGroups({
    registrants,
    itemIdArray,
    activeSearchString,
    users,
    settings,
  })

  const [pageLoading, setPageLoading] = useState(true)
  const pageLoadingDelay = 100

  const [pageLoadingExpired, setPageLoadingExpired] = useState(false)
  const pageLoadingExpiredDelay = 1500

  const itemsName = 'Items'
  const itemName = itemsName.replace(/e?s$/, '')

  useEffect(() => {
    updateBottomMenu({
      itemTitle,
      buttonTitle: `Choose Another ${itemName}`,
      buttonLink: `/${itemsName.toLowerCase()}`,
    })
  }, [updateBottomMenu, itemTitle, itemName, itemsName])

  useEffect(() => {
    dispatch(updateTopMenuContent(<Search />))
    dispatch(historySaveItemPathname(window.location.pathname))
    const timeout = setTimeout(() => setPageLoading(false), pageLoadingDelay)

    return () => timeout && clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // do not stay in loading state more than 1.5 seconds
    const timeout = setTimeout(() => setPageLoadingExpired(true), pageLoadingExpiredDelay)

    return () => timeout && clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (pageLoading) {
    return <PageLoader />
  }

  const emptyItemsHeaderTitleLoaded = activeSearchString
    ? 'No matching registrants found'
    : 'No registrants found'
  const emptyItemsHeaderTitle = pageLoadingExpired
    ? emptyItemsHeaderTitleLoaded
    : 'Loading registrants...'

  return (
    <>
      <RestoreScrollPosition />
      <Grid
        container
        style={{ marginTop: '5rem', marginBottom: '2rem' }}
        className="grid-container"
      >
        <Grid.Row>
          <Grid.Column width={16}>
            {registrantGroups.length ? (
              ''
            ) : (
              <div className="registrants-group">
                <Header as="h1" textAlign="center">
                  {emptyItemsHeaderTitle}
                </Header>
              </div>
            )}
            {registrantGroups.map(({ checkedInStatus, header }) => (
              <div key={checkedInStatus.toString()} className="registrants-group">
                <Header as="h1" textAlign="center">
                  {header}
                  <Header.Subheader className="subheader">
                    {checkedInStatus === 'any' &&
                      !getRegistrantsSubheaderNoneFound(
                        true,
                        registrantCounts,
                        activeSearchString,
                        itemId
                      ) && (
                        <div>
                          Checked-in:
                          {` ${getRegistrantsSubheader(
                            true,
                            registrantCounts,
                            activeSearchString,
                            itemId
                          )}`}
                        </div>
                      )}
                    {checkedInStatus === 'any' &&
                      !getRegistrantsSubheaderNoneFound(
                        false,
                        registrantCounts,
                        activeSearchString,
                        itemId
                      ) && (
                        <div>
                          Not checked-in:
                          {` ${getRegistrantsSubheader(
                            false,
                            registrantCounts,
                            activeSearchString,
                            itemId
                          )}`}
                        </div>
                      )}
                    {checkedInStatus !== 'any' && (
                      <div>
                        {getRegistrantsSubheader(
                          checkedInStatus,
                          registrantCounts,
                          activeSearchString,
                          itemId
                        )}
                      </div>
                    )}
                  </Header.Subheader>
                </Header>
                <Card.Group>
                  {getFilteredRegistrants(
                    registrants,
                    itemIdArray,
                    activeSearchString,
                    users,
                    settings
                  )
                    .sort(registrantSort)
                    .filter(
                      reg =>
                        checkedInStatus === 'any' ||
                        isCheckedIn(reg.checkins, reg.items) === checkedInStatus
                    )
                    .map(reg => (
                      <RegistrantCard key={`${reg.id}-${activeSearchString}`} reg={reg} />
                    ))}
                </Card.Group>
              </div>
            ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default RegistrantsList
