import './style.css'
import React, { ChangeEvent, useEffect } from 'react'
import {
  clearSearchInputFieldTimeouts,
  focusSearchInputFieldOnStart,
  onClick,
  onKeyDown,
} from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import { Input } from 'semantic-ui-react'
import { selectors as searchSelectors, searchUpdateSearchString } from './reducer'

const Search = () => {
  const dispatch = useAppDispatch()
  const searchUpdate = function (
    this: any,
    ...args: [_ev: ChangeEvent<HTMLInputElement> | null, { value: string }]
  ) {
    return dispatch(searchUpdateSearchString.apply(this, args))
  }

  const searchString = useAppSelector(state => searchSelectors.searchString(state))

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown(searchUpdate))
    return () => {
      clearSearchInputFieldTimeouts()
      document.removeEventListener('keydown', onKeyDown(searchUpdate))
    }
  })

  return (
    <Input
      {...{
        ref: focusSearchInputFieldOnStart,
        placeholder: 'Search regex...',
        icon: {
          name: 'x',
          circular: true,
          link: true,
          onClick: onClick(searchUpdate),
        },
        inverted: true,
        onChange: searchUpdate,
        onKeyDown: onKeyDown(searchUpdate),
        className: 'search-box',
      }}
      value={searchString}
    />
  )
}

export default Search
