import './style.css'

import {
  Button,
  Card,
  Checkbox,
  Confirm,
  DropdownProps,
  Form,
  Grid,
  Icon,
  InputOnChangeData,
  Item,
} from 'semantic-ui-react'
import OrderStatus from 'AppSrc/order/component'
import { orderUpdateRequest, selectors as orderUpdateSelectors } from 'AppSrc/order/reducer'
import React, { useCallback, useEffect, useState } from 'react'
import Refresh from 'AppSrc/refresh/component'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import {
  debounce,
  getDropDownInfo,
  getOrderProductMetadata,
  getRegInfo,
  getRegistrantNamesForLocationIndex,
  getRegistrantOrderIdsByName,
  getValuesProductsInfo,
  newRegInfo,
  objHasKey,
  updateTopMenuTitle,
} from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import { useLocation, useParams } from 'react-router'

import { Link } from 'react-router-dom'
import MetaDataBirthday from 'AppSrc/metadata/MetaDataBirthday'
import MetaDataVaccination from 'AppSrc/metadata/MetaDataVaccination'
import PageLoader from 'AppSrc/components/pageloader'
import Prompt from 'AppSrc/components/prompt'
import RegistrantCardItemExtra from './RegistrantCardItemExtra'
import RegistrantEditCardItemExtra from './RegistrantEditCardItemExtra'
import RegistrantEditCardOrderTable from './RegistrantEditCardOrderTable'
import type { UpdateBottomMenuType } from 'AppSrc/components/bottommenu'
import debug from 'debug'
import { diff } from 'deep-diff'
import { decode as heDecode } from 'html-entities'
import { selectors as historySelectors } from 'AppSrc/history/reducer'
import queryString from 'query-string'
import { stateIndexType } from 'AppSrc/refresh/types'
import { TopMenuActionTypes, updateTopMenuContent } from 'AppSrc/components/topmenu/reducer'

debug.enable('registrants/RegistrantEditCard:*')
// const log = debug('registrants/RegistrantEditCard:log')
const info = debug('registrants/RegistrantEditCard:info')
// const error = debug('registrants/RegistrantEditCard:error')

type Props = {
  create: boolean
  updateBottomMenu: UpdateBottomMenuType
}

const RegistrantEditCard = ({ create, updateBottomMenu }: Props) => {
  const dispatch = useAppDispatch()

  const updateTopMenu = useCallback(
    function updateTopMenuContentFn(
      this: TopMenuActionTypes,
      ...args: Parameters<typeof updateTopMenuContent>
    ) {
      return dispatch(updateTopMenuContent.apply(this, args))
    },
    [dispatch]
  )

  const items = useAppSelector(state => refreshSelectors.items(state))
  const allItems = useAppSelector(state => refreshSelectors.allItems(state))
  const registrants = useAppSelector(state => refreshSelectors.registrants(state))
  const propsUsers = useAppSelector(state => refreshSelectors.users(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))
  const refreshStatus = useAppSelector(state => refreshSelectors.refreshStatus(state))
  const historyLastItemPathname = useAppSelector(state =>
    historySelectors.historyLastItemPathname(state)
  )
  const orderUpdateStatus = useAppSelector(state => orderUpdateSelectors.orderUpdateStatus(state))

  const match = { params: useParams() }
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const defaultRegId = create ? newRegInfo.id! : decodeURIComponent(match.params.registrantId!)
  const newRegInit = create ? newRegInfo : null
  const query = queryString.parse(location.search)
  const locationIndex = Number(query.index) || 0

  const {
    reg: defaultReg,
    regName: defaultRegName,
    userRoles: defaultUserRoles,
  } = getRegInfo(defaultRegId, locationIndex, newRegInit, items, registrants, propsUsers, {
    updateNewReg: true,
  })

  const [loadingExpired, setLoadingExpired] = useState<boolean>(!create)
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(create)
  const [updateDisabledUser, setUpdateDisabledUser] = useState<boolean>(create)
  const [updateDisabledDiscount, setUpdateDisabledDiscount] = useState<boolean>(false)
  const [values, setValues] = useState<{ [id: string]: Array<string> }>({})
  const [defaultValues, setDefaultValues] = useState<{ [id: string]: Array<string> }>({})
  const [orderDiscount, setOrderDiscount] = useState<{ [id: string]: number }>({})
  const [orderDiscountInput, setOrderDiscountInput] = useState<{ [id: string]: number }>({})
  const [updateRegistrantConfirmOpen, setUpdateRegistrantConfirmOpen] = useState<boolean>(false)
  const [regId, setRegId] = useState<number | string>(defaultRegId)
  const [regName, setRegName] = useState<string>(defaultRegName)
  const [userRoles, setUserRoles] = useState<Array<checkin.userRoleEnum>>(defaultUserRoles)
  const [newReg, setNewReg] = useState(create ? defaultReg : null)
  const [newUser, setNewUser] = useState<DbUserType>({} as DbUserType)
  const [users, setUsers] = useState<DbUsersType>({})
  const [usersOptions, setUsersOptions] = useState<Array<UsersOptionsType>>([])
  const [prevRefreshStatus, setPrevRefreshStatus] = useState(refreshStatus)
  const [isVolunteer, setIsVolunteer] = useState(false)
  const pageLoadingDelay = 2000

  const defaultNewReg = create ? newReg : null

  useEffect(() => {
    updateBottomMenu({
      itemTitle: `${create ? 'New' : 'Edit'} Registration`,
      buttonTitle: 'Back',
      buttonLink: historyLastItemPathname,
    })
  }, [updateBottomMenu, create, historyLastItemPathname])

  useEffect(() => {
    const { reg } = getRegInfo(regId, locationIndex, defaultNewReg, items, registrants, propsUsers)

    if (reg) {
      const names = getRegistrantNamesForLocationIndex(reg, create, locationIndex)
      const newDefaultValues = {} as {
        [id: string]: string[]
      }
      names.forEach(name => {
        const orderIds = getRegistrantOrderIdsByName(reg.items!, reg, name)
        const { dropDownDefaultValueAll } = getDropDownInfo(
          reg,
          name,
          orderIds,
          create,
          {},
          items,
          allItems
        )
        Object.keys(dropDownDefaultValueAll).forEach(orderId => {
          newDefaultValues[orderId] = dropDownDefaultValueAll[orderId]
        })
      })

      if (diff(defaultValues, newDefaultValues)) {
        // log('useEffect: new defaultValues/values', newDefaultValues)
        // If default values have changed (from a props update), we reset values to match
        setDefaultValues(newDefaultValues)
        setValues(newDefaultValues)
      }
    }
  }, [
    setDefaultValues,
    setValues,
    regId,
    locationIndex,
    defaultNewReg,
    items,
    allItems,
    registrants,
    propsUsers,
    create,
    defaultValues,
  ])

  useEffect(() => {
    if (!create) return

    // log('useEffect setDropdownUsersOptions')
    const newUsersOptions: Array<UsersOptionsType> = Object.keys(propsUsers)
      .filter(userId => propsUsers[userId].firstname && propsUsers[userId].lastname)
      .sort((a, b) => {
        const userA = propsUsers[a]
        const userB = propsUsers[b]
        const userBName = `${userA.lastname}, ${userA.firstname} / ${userA.email}`.toLowerCase()
        const userAName = `${userB.lastname}, ${userB.firstname} / ${userB.email}`.toLowerCase()
        return userAName > userBName ? -1 : 0
      })
      .map((userId, index) => {
        const user = propsUsers[userId]
        return {
          key: index,
          value: userId,
          text: heDecode(`${user.lastname}, ${user.firstname} / ${user.email}`),
        }
      })

    setUsers(propsUsers)
    setUsersOptions(newUsersOptions)
    setDropDownLoading(false)
  }, [propsUsers, create])

  useEffect(() => {
    updateTopMenuTitle(updateTopMenuContent, regName)
    const timeout = setTimeout(() => setLoadingExpired(true), pageLoadingDelay)

    return () => timeout && clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { regName: newRegName } = getRegInfo(
      regId,
      locationIndex,
      newReg,
      items,
      registrants,
      propsUsers
    )
    // log('useEffect', regId, regName, newReg)

    updateTopMenuTitle(updateTopMenu, newRegName)

    // If we have an order status update, reset corresponding values to defaultValues
    const orderIds = [] as string[]
    const newRefreshStatus = { ...prevRefreshStatus }
    for (const orderId of Object.keys(defaultValues)) {
      const refreshStatusId = `#${orderId}` as stateIndexType
      if (diff(prevRefreshStatus[refreshStatusId], refreshStatus[refreshStatusId])) {
        orderIds.push(orderId)
        newRefreshStatus[refreshStatusId] = refreshStatus[refreshStatusId]
      }
    }

    if (orderIds.length) {
      const newValues = JSON.parse(JSON.stringify(values)) // deep copy
      orderIds.forEach(orderId => {
        newValues[orderId] = defaultValues[orderId]
      })
      // log('useEffect', newValues)
      setValues(newValues)
      setPrevRefreshStatus(newRefreshStatus)
    }
  }, [
    regId,
    locationIndex,
    newReg,
    items,
    registrants,
    propsUsers,
    setValues,
    setPrevRefreshStatus,
    defaultValues,
    prevRefreshStatus,
    refreshStatus,
    updateTopMenu,
    values,
  ])

  const getTotal = ({
    localReg,
    localValues,
    localUserRoles,
  }: {
    localReg: RegistrantType
    localValues: { [id: string]: Array<string> }
    localUserRoles?: Array<checkin.userRoleEnum> | []
  }) => {
    const orderId = Object.keys(localReg.total || {})[0]
    // log('getTotal getValuesProductsInfo', allItems, userRoles)
    const { orderTotal } = getValuesProductsInfo(localValues[orderId], allItems, localUserRoles)
    return { orderId, orderTotal }
  }

  const onOrderTableChange = ({
    values: localValues,
    orderDiscount: localOrderDiscount,
    userRoles: localUserRoles,
    zeroOrderTotal,
  }: OrderTableChangeType) => {
    // log('RegistrantEditCard onOrderTableChange', { localValues })

    if (create) {
      const localReg = { ...newReg } as RegistrantType

      if (localValues) {
        // log('newReg', newReg)
        const { orderId, orderTotal } = getTotal({ localReg, localValues, localUserRoles })
        if (orderTotal !== localReg.total?.[orderId]) localReg.total = { [orderId]: orderTotal }
      }

      if (localOrderDiscount || isVolunteer || zeroOrderTotal !== undefined) {
        const orderId = Object.keys(localReg.discount_total || {})[0]

        // If volunteer, match order total
        const discount =
          isVolunteer || zeroOrderTotal
            ? localReg.total[orderId]
            : (localOrderDiscount ? localOrderDiscount[orderId] : orderDiscount[orderId]) || 0

        localReg.discount_total = {}
        localReg.discount_total[orderId] = discount
        const localUpdateDisabledDiscount =
          objHasKey(localReg.total!, orderId) &&
          Number(localReg.discount_total[orderId]) > localReg.total![orderId]
        setUpdateDisabledDiscount(localUpdateDisabledDiscount)
        setOrderDiscount({ ...localOrderDiscount, [orderId]: discount })
        if (discount !== orderDiscountInput[orderId] || 0) {
          setOrderDiscountInput({ ...orderDiscountInput, [orderId]: discount })
        }
      }

      setNewReg(localReg)
    }

    if (localValues) setValues(localValues)
    if (localUserRoles) setUserRoles(localUserRoles)
  }

  const updateRegistrantConfirmShow = () => {
    setUpdateRegistrantConfirmOpen(true)
  }

  const updateRegistrantConfirmHide = () => {
    setUpdateRegistrantConfirmOpen(false)
  }

  const updateRegistrant = () => {
    // defaultValues: original items ('quantity x productId')
    // values: updated items (either 'quantity x productId' or '#number productId')
    // log('updateRegistrant', defaultValues, values)
    const changes = {} as { [id: string]: { [pid: string]: number } }
    const prices = {} as { [id: string]: { [pid: string]: number } }
    const metadata = {} as { [id: string]: { [pid: string]: wcOrderProductMetaDataType } }

    // gather original values
    Object.keys(defaultValues).forEach(orderId => {
      // log('updateRegistrant getValuesProductsInfo 1', allItems)
      const { info: productsInfo } = getValuesProductsInfo(
        defaultValues[orderId],
        allItems,
        userRoles
      )
      changes[orderId] || (changes[orderId] = {})
      prices[orderId] || (prices[orderId] = {})
      metadata[orderId] || (metadata[orderId] = {})
      Object.keys(productsInfo).forEach(productId => {
        changes[orderId][productId] || (changes[orderId][productId] = 0)
        changes[orderId][productId] -= productsInfo[productId].quantity
        prices[orderId][productId] = productsInfo[productId].price
        metadata[orderId][productId] = getOrderProductMetadata(
          productId,
          productsInfo[productId],
          allItems
        )
      })
    })

    // process updated values (subtract from original quantities)
    Object.keys(values).forEach(orderId => {
      // log('updateRegistrant getValuesProductsInfo 2', allItems)
      const { info: productsInfo } = getValuesProductsInfo(values[orderId], allItems, userRoles)
      changes[orderId] || (changes[orderId] = {})
      prices[orderId] || (prices[orderId] = {})
      Object.keys(productsInfo).forEach(productId => {
        changes[orderId][productId] || (changes[orderId][productId] = 0)
        changes[orderId][productId] += productsInfo[productId].quantity
        prices[orderId][productId] = productsInfo[productId].price
        metadata[orderId][productId] = getOrderProductMetadata(
          productId,
          productsInfo[productId],
          allItems
        )
      })
      Object.keys(changes[orderId]).forEach(productId => {
        if (changes[orderId][productId] === 0) {
          delete changes[orderId][productId]
          delete prices[orderId][productId]
          delete metadata[orderId][productId]
        }
      })
    })

    Object.keys(changes).forEach(orderId => {
      if (!Object.keys(changes[orderId]).length) {
        delete changes[orderId]
        delete prices[orderId]
        delete metadata[orderId]
      }
    })
    // log('updateRegistrant', changes, prices)

    type OrderCreateUserType = Pick<DbUserType, 'id'> & Partial<DbUserType>
    let orderCreate: {
      create: boolean
      user: OrderCreateUserType
      userRoles: Array<checkin.userRoleEnum>
    }
    let registrantId = regId
    if (create) {
      let user: OrderCreateUserType
      if (objHasKey(propsUsers, regId.toString())) {
        user = { id: regId.toString() }
      } else {
        user = { ...newUser }
        registrantId = user.email!.toLowerCase() // force user email to lowercase
      }
      orderCreate = {
        create,
        user,
        userRoles,
      }
    }

    Object.keys(changes).forEach(orderId => {
      let localOrderCreate
      if (orderCreate) {
        localOrderCreate = JSON.parse(JSON.stringify(orderCreate)) // deep copy
        localOrderCreate.total = newReg!.total![orderId]
        if (Number(newReg!.total![orderId]) === Number(newReg!.discount_total![orderId])) {
          localOrderCreate.set_paid = true
        }
        if (newReg!.discount_total![orderId] && Number(newReg!.discount_total![orderId])) {
          localOrderCreate.discount_total = newReg!.discount_total![orderId]
        }
      }

      if (isVolunteer) {
        localOrderCreate.meta_data = [
          {
            key: 'order_notes',
            value: 'Volunteer',
          },
        ]
      }

      info(
        'Order update request: orderId',
        orderId,
        'changes',
        changes[orderId],
        prices[orderId],
        metadata[orderId],
        localOrderCreate
      )
      dispatch(
        orderUpdateRequest(`items-${orderId}`, registrantId, {
          orderId,
          items: {
            changes: changes[orderId],
            prices: prices[orderId],
            metadata: metadata[orderId],
          },
          ...localOrderCreate,
        })
      )
    })

    updateRegistrantConfirmHide()
  }

  const userDropdownChange = (
    evt: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newRegId = (data.value as string) || newRegInfo.id! // reset ID if dropdown value is empty

    const {
      reg,
      regName: newRegName,
      userRoles: newUserRoles,
    } = getRegInfo(newRegId, locationIndex, newReg, items, registrants, propsUsers, {
      updateNewReg: true,
    })
    const localUpdateDisabledUser = reg.id === newRegInfo.id
    // restore discount
    reg.discount_total = JSON.parse(JSON.stringify(newReg?.discount_total))
    // recalculate total based on user roles
    // log('userDropdownChange', values)
    const { orderId, orderTotal } = getTotal({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localReg: newReg!,
      localValues: values,
      localUserRoles: newUserRoles,
    })
    reg.total = { [orderId]: orderTotal }
    if (isVolunteer) {
      reg.discount_total![orderId] = reg.total[orderId]
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const localUpdateDisabledDiscount = Number(reg.discount_total![orderId]) > reg.total[orderId]

    // log('userDropdownChange', regName, userRoles)
    updateTopMenuTitle(updateTopMenuContent, newRegName)

    setRegId(newRegId)
    setRegName(newRegName)
    setNewReg(reg)
    setUpdateDisabledUser(localUpdateDisabledUser)
    setUpdateDisabledDiscount(localUpdateDisabledDiscount)
    setUserRoles(newUserRoles)
  }

  const onUserChange = (field: string) =>
    debounce((_evt: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const localNewUser = { ...newUser, [field]: data.value }
      const localUpdateDisabledUser = !(
        localNewUser.firstname &&
        localNewUser.lastname &&
        localNewUser.email
      )
      setNewUser(localNewUser)
      setUpdateDisabledUser(localUpdateDisabledUser)
    }, 500)

  if (!loadingExpired && Object.keys(registrants).length === 0) {
    return (
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <PageLoader />
      </Grid>
    )
  }

  const onClickIsVolunteer = (val: boolean) => {
    setIsVolunteer(val)
    onOrderTableChange({ zeroOrderTotal: val })
  }

  const { reg } = getRegInfo(regId, locationIndex, defaultNewReg, items, registrants, users)

  if (!reg) return null

  // FIXME: this can probably be done better
  const regItems = reg.items // no filtering here
  const orderUpdateStatuses = (name: string) => {
    const orderIds = getRegistrantOrderIdsByName(regItems, reg, name)
    const statuses = orderIds.map(orderId => orderUpdateStatus[`items-${orderId}`]).join('')
    return statuses
  }

  const names = getRegistrantNamesForLocationIndex(reg, create, locationIndex)

  const hideEmail = settings.uiRegistrants && settings.uiRegistrants.hideEmailAddress

  const updateDisabledOrderUpdate = names.reduce(
    (acc, name) =>
      acc &&
      !!(orderUpdateStatuses(name) || '').match(new RegExp(`ing${create ? '|succeeded' : ''}`)),
    true
  )
  const updateDisabledItems = !diff(values, defaultValues)
  const updateDisabled =
    updateDisabledItems || updateDisabledUser || updateDisabledDiscount || updateDisabledOrderUpdate

  const usersDefaultValue = objHasKey(users, regId.toString()) ? regId : undefined

  return (
    <Grid
      container
      verticalAlign="middle"
      style={{
        minHeight: '100vh',
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: '5rem',
        paddingBottom: '5rem',
      }}
      className={`registrant-edit-card-grid grid-container-wide ${
        create ? 'registrant-edit-card-create' : 'registrant-edit-card'
      }`}
    >
      <Prompt
        when={!updateDisabled}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Grid.Row>
        <Grid.Column width={16}>
          <Card fluid raised>
            <Card.Content>
              <Grid
                container
                verticalAlign="middle"
                divided="vertically"
                className="registrant-edit-card-content-grid"
              >
                {names.map(name => (
                  <Grid.Row key={create ? 'create' : name}>
                    <Grid.Column width={16}>
                      <Item.Group>
                        <Item>
                          <Item.Image
                            size="small"
                            circular
                            src={reg.avatar}
                            as={Link}
                            to={historyLastItemPathname}
                            className="avatar"
                          />
                          <Item.Content verticalAlign="middle" className="registrant-card-content">
                            <Button
                              floated="right"
                              color="green"
                              size="massive"
                              compact
                              disabled={updateDisabled}
                              onClick={updateRegistrantConfirmShow}
                              loading={
                                !!(orderUpdateStatuses(name) || '').match(/ing/) /* FIXME /ing/ */
                              }
                              className="update-button"
                            >
                              <Icon name="save" />
                              {`${create ? 'Create' : 'Update'}`}
                            </Button>
                            <Confirm
                              size="fullscreen"
                              content={`Are you sure you want to ${
                                create ? 'create this order' : 'update the registrant order(s)'
                              }?`}
                              open={updateRegistrantConfirmOpen}
                              onCancel={updateRegistrantConfirmHide}
                              onConfirm={updateRegistrant}
                              confirmButton={`Yes, ${create ? 'Create' : 'Update'}`}
                              cancelButton="No"
                            />
                            <Item.Header style={{ width: '80%' }}>
                              <div className="registrant-name-form">
                                <Form size="big">
                                  <Form.Group>
                                    {!create || usersDefaultValue ? (
                                      <Form.Field width={8}>
                                        <div className="registrant-name">{heDecode(name)}</div>
                                        {typeof reg.id !== 'number' ? null : (
                                          <Refresh
                                            componentType="circular button"
                                            idx={`#${reg.id}`}
                                            popupProps={{ offset: [-17, 0], size: 'huge' }}
                                            refreshEndpoints={[
                                              {
                                                endpoint: `refresh/users/${reg.id}`,
                                              },
                                            ]}
                                          />
                                        )}
                                      </Form.Field>
                                    ) : (
                                      [1].map(() => {
                                        const rows = [
                                          <Form.Input
                                            width={4}
                                            key="firstname"
                                            placeholder="First name"
                                            onChange={onUserChange('firstname')}
                                            tabIndex={0}
                                          />,
                                          <Form.Input
                                            width={4}
                                            key="lastname"
                                            placeholder="Last name"
                                            onChange={onUserChange('lastname')}
                                            tabIndex={0}
                                          />,
                                        ]
                                        return rows
                                      })
                                    )}
                                    {!create ? null : (
                                      <Form.Dropdown
                                        width={8}
                                        className="select-user"
                                        deburr
                                        selection
                                        search
                                        clearable
                                        placeholder="Guest"
                                        lazyLoad
                                        loading={dropDownLoading}
                                        options={usersOptions}
                                        value={usersDefaultValue}
                                        onChange={userDropdownChange}
                                        selectOnBlur={false}
                                        selectOnNavigation={false}
                                        tabIndex={1 /* eslint-disable-line */}
                                      />
                                    )}
                                  </Form.Group>
                                  {!create || usersDefaultValue ? null : (
                                    <Form.Group className="registrant-email-form">
                                      <Form.Input
                                        width={8}
                                        placeholder="Email"
                                        onChange={onUserChange('email')}
                                        tabIndex={0}
                                      />
                                    </Form.Group>
                                  )}
                                </Form>
                              </div>
                            </Item.Header>
                            <Item.Meta>
                              {!(!create || usersDefaultValue) ? null : (
                                <div
                                  style={{ display: hideEmail ? 'none' : 'inline-block' }}
                                  className="registrant-email-id"
                                >
                                  {reg.email || reg.id}
                                </div>
                              )}
                              <MetaDataBirthday
                                key={`${reg.id}-birthday`}
                                regId={reg.id.toString()}
                              />
                              {settings.uiRegistrants.hideVaccinationStatus ? null : (
                                <MetaDataVaccination
                                  key={`${reg.id}-vaccination`}
                                  orderIds={getRegistrantOrderIdsByName(regItems!, reg, name)}
                                  regId={reg.id.toString()}
                                  regOrderExtra={reg.order_extra}
                                />
                              )}
                              {Object.keys(reg.order_status || {})
                                .filter(
                                  orderId =>
                                    getRegistrantOrderIdsByName(regItems!, reg, name).indexOf(
                                      orderId
                                    ) !== -1
                                )
                                .map(orderId => (
                                  <OrderStatus key={orderId} reg={reg} orderId={Number(orderId)} />
                                ))}
                            </Item.Meta>
                            <Item.Description style={{ marginTop: '1rem' }}>
                              <RegistrantEditCardOrderTable
                                reg={reg}
                                name={name}
                                orderIds={getRegistrantOrderIdsByName(regItems!, reg, name)}
                                values={values}
                                defaultValues={defaultValues}
                                onOrderTableChange={onOrderTableChange}
                                userRoles={userRoles}
                                create={create}
                                orderDiscountInput={orderDiscountInput}
                                setOrderDiscountInput={setOrderDiscountInput}
                              />
                            </Item.Description>
                            <Item.Extra>
                              {create && (
                                <div style={{ float: 'right' }}>
                                  <Checkbox
                                    className="checkbox-after-label"
                                    label="Volunteer"
                                    onChange={(_evt, data) =>
                                      onClickIsVolunteer(data.checked ?? false)
                                    }
                                  />
                                </div>
                              )}
                              <RegistrantCardItemExtra reg={reg} name={name} />
                              <RegistrantEditCardItemExtra reg={reg} name={name} />
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                      </Item.Group>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default RegistrantEditCard
