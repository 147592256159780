import React from 'react'
import debug from 'debug'
import { List, Accordion, Icon, Modal } from 'semantic-ui-react'
import { selectors as searchSelectors } from 'AppSrc/search/reducer'
import { getRegistrantOrderIdsByName } from 'AppSrc/components/registrants/helpers'
import { useAppSelector, useAppDispatch } from 'AppSrc/store'
import './style.css'
import { orderUpdateRequest } from 'AppSrc/order/reducer'

debug.enable('orderrefunds/component:*')
// const log = debug('orderrefunds/component:log')
// const info = debug('orderrefunds/component:info')
// const error = debug('orderrefunds/component:error')

type Props = {
  reg: RegistrantType
  name: string
  orderId?: string
  openAccordion?: boolean
  className: string
}

const OrderRefunds = ({
  reg,
  name,
  orderId: sOrderId = '',
  openAccordion = false,
  className,
}: Props) => {
  const dispatch = useAppDispatch()

  const activeSearchString = useAppSelector(state => searchSelectors.activeSearchString(state))

  const onClick = (orderId: number, refundId: number) => {
    const idx = `deleterefund-${orderId}-${refundId}`
    // log('orderUpdateRequest', idx, reg.id, { orderId, refund: { id: refundId } })
    dispatch(
      orderUpdateRequest(idx, reg.id as string, {
        orderId,
        refund: { id: refundId },
      })
    )
  }

  const orderIds = Object.keys(reg.refunds || [])
    .map(orderId => Number(orderId))
    .filter(orderId => !sOrderId || orderId === Number(sOrderId))
    .filter(
      orderId =>
        getRegistrantOrderIdsByName(reg.items, reg, name).indexOf(orderId.toString()) !== -1
    )
    .sort((a, b) => a - b)

  return (
    <div className={className}>
      {orderIds.map(orderId => (
        <Accordion
          key={`accordion-refunds-${orderId}`}
          style={{ lineHeight: '1.4em' }}
          defaultActiveIndex={openAccordion || activeSearchString ? [0] : []}
          exclusive={false}
          panels={[
            {
              key: `order-refunds-${orderId}`,
              title: `REFUNDS${orderIds.length > 1 ? ` #${orderId}` : ''}`,
              content: {
                content: (
                  <List>
                    {Object.keys(reg.refunds[orderId])
                      .map(refundId => Number(refundId))
                      .sort((a, b) => a - b)
                      .map(refundId => (
                        <List.Item key={refundId}>
                          <Modal
                            trigger={
                              <Icon
                                name={refundId >= 0 ? 'close' : 'circle'}
                                size={refundId >= 0 ? 'small' : 'mini'}
                              />
                            }
                            header="Delete Refund"
                            content={
                              <div className="content">
                                Do you want to delete this refund?
                                <div style={{ marginTop: '1em' }}>
                                  ${reg.refunds[orderId][refundId].total}
                                  {reg.refunds[orderId][refundId].reason
                                    ? ` - ${reg.refunds[orderId][refundId].reason}`
                                    : ''}
                                </div>
                              </div>
                            }
                            actions={[
                              'No',
                              {
                                key: 'delete-refund',
                                content: 'Yes, delete it',
                                negative: true,
                                onClick: () => onClick(orderId, refundId),
                              },
                            ]}
                          />
                          <List.Content key={refundId}>
                            <em>
                              ${reg.refunds[orderId][refundId].total}
                              {reg.refunds[orderId][refundId].reason
                                ? ` - ${reg.refunds[orderId][refundId].reason}`
                                : ''}
                            </em>
                          </List.Content>
                        </List.Item>
                      ))}
                  </List>
                ),
                key: 'order-refunds',
              },
            },
          ]}
          className="order-refunds-accordion"
        />
      ))}
    </div>
  )
}

export default OrderRefunds
