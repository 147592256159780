import React from 'react'
import { Icon } from 'semantic-ui-react'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { useAppSelector } from 'AppSrc/store'
import { selectors as orderUpdateSelectors } from 'AppSrc/order/reducer'
import { getMatchingUniqueOrderItemKeys } from './helpers'

import './style.css'

type Props = {
  reg: RegistrantType
  name: string
}

const RegistrantEditCardItemExtra = ({ reg, name }: Props) => {
  const orderUpdateStatus = useAppSelector(state => orderUpdateSelectors.orderUpdateStatus(state))
  const orderUpdateErrorData = useAppSelector(state => orderUpdateSelectors.errorData(state))

  const matchingUniqueOrderItemKeys = getMatchingUniqueOrderItemKeys(reg.items!, reg, name)
  if (!matchingUniqueOrderItemKeys.length) {
    return null
  }

  return (
    <>
      {matchingUniqueOrderItemKeys.map(key => (
        <div key={key}>
          {!!(orderUpdateStatus[`cancel-${reg.items?.[key].id}`] || '').match(/rejected/) && (
            <div style={{ color: 'red' }}>
              <Icon name="exclamation triangle" color="red" />
              CANCEL FAILED:
              {orderUpdateErrorData[`cancel-${reg.items?.[key].id}`]}
            </div>
          )}
          {!!(orderUpdateStatus[`refund-${reg.items?.[key].id}`] || '').match(/rejected/) && (
            <div style={{ color: 'red' }}>
              <Icon name="exclamation triangle" color="red" />
              REFUND FAILED:
              {orderUpdateErrorData[`refund-${reg.items?.[key].id}`]}
            </div>
          )}
          {!!(orderUpdateStatus[`items-${reg.items?.[key].id}`] || '').match(/rejected/) && (
            <div style={{ color: 'red' }}>
              <Icon name="exclamation triangle" color="red" />
              UPDATE FAILED:
              {orderUpdateErrorData[`items-${reg.items?.[key].id}`]}
            </div>
          )}
          {!!(orderUpdateStatus[`note-${reg.items?.[key].id}`] || '').match(/rejected/) && (
            <div style={{ color: 'red' }}>
              <Icon name="exclamation triangle" color="red" />
              ORDER NOTE FAILED:
              {orderUpdateErrorData[`note-${reg.items?.[key].id}`]}
            </div>
          )}
        </div>
      ))}
    </>
  )
}

export default RegistrantEditCardItemExtra
